import logo from './logo.svg';
import './App.css';
import styled from 'styled-components';
import Card from './Card';
import { image_01, image_02, image_03, image_04 } from './assets/images';
import { useEffect, useState } from 'react';
import './Button.css';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useToneAudio from './hooks/useToneAudio';
import { delay } from './utils/timer';
import Confetti from 'react-confetti';

let SERVER_URL = '';
if (process.env.NODE_ENV === 'development') {
  SERVER_URL = 'http://localhost:3336';
}
console.log('SERVER_URL: ', SERVER_URL);

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: row; */
`;

function App() {
  const cardList = [
    {
      type: 'Normal',
      title: 'Lorem Ipsum',
      image: image_01,
      description: `Lorem Ipsum is simply dummy text of the printing and
      typesetting industry. Lorem Ipsum has been the industry's
      standard dummy text ever since the 1500s`,
    },
    {
      type: 'Normal',
      title: 'Lorem Ipsum 2',
      image: image_02,
      description: `Lorem Ipsum is simply dummy text of the printing and
      typesetting industry. Lorem Ipsum has been the industry's
      standard dummy text ever since the 1500s`,
    },
    {
      type: 'Normal',
      title: 'Lorem Ipsum 3',
      image: image_03,
      description: `Lorem Ipsum is simply dummy text of the printing and
      typesetting industry.`,
    },
    {
      type: 'Normal',
      title: 'Lồng đèn con thỏ',
      image: image_04,
      description: `Một cái lồng đèn hot trend trung thu 2023, với một con thỏ đáng yêu trên lồng đèn. Bạn sẽ gặp may mắn khi sở hữu lồng đèn này.`,
    },
  ];

  const [shuffleCards, setShuffleCards] = useState();
  const [currentCardIndex, setCurrentCardIndex] = useState();
  const GAME_STATE = {
    START: 'START',
    PLAYING: 'PLAYING',
    END: 'END',
  };
  const [gameState, setGameState] = useState(GAME_STATE.START);
  const [playConfetti, setPlayConfetti] = useState(false);
  const [rotate, setRotate] = useState(false);
  const { play: playDrawCard, stop } = useToneAudio('/audio/draw-card.mp3');
  // 'https://tonejs.github.io/audio/drum-samples/loops/ominous.mp3'

  useEffect(() => {
    if (playConfetti) {
    }
  }, [playConfetti]);

  const clickNext = async () => {
    if (!rotate) {
      // toast.info('Hãy mở card trước khi bấm next');
      setRotate(true);
      return;
    }
    setRotate(false);

    playDrawCard();
    await delay(200);

    // console.log('clickNext: ');
    if (currentCardIndex === shuffleCards.length - 1) {
      setGameState(GAME_STATE.START);
      setPlayConfetti(true);
      toast.info('End game');
    } else {
      setCurrentCardIndex(currentCardIndex + 1);
    }
  };

  const clickPlay = () => {
    console.log('clickPlay: ');
    const cards = _.shuffle(cardList);
    console.log('cards: ', cards);
    setShuffleCards(cards);
    setCurrentCardIndex(0);
    setGameState(GAME_STATE.PLAYING);
    setPlayConfetti(false);
  };

  return (
    <>
      {playConfetti && (
        <Confetti
          width={window.innerWidth}
          height={window.innerHeight}
          // wind={0.01}
        />
      )}
      <Center style={{ backgroundColor: '' }}>
        <section>
          <h1 style={{ textAlign: 'center' }}>RANDOM CARD</h1>
          {gameState === GAME_STATE.START && (
            <Center style={{ backgroundColor: '', marginTop: 16 }}>
              <button class="button" onClick={clickPlay}>
                PLAY
              </button>
            </Center>
          )}
          {gameState === GAME_STATE.PLAYING && (
            <>
              <div
                style={{
                  display: 'flex',
                  backgroundColor: '',
                  justifyContent: 'center',
                  flexDirection: 'row',
                }}
              >
                <Card
                  {...{
                    ...(shuffleCards[currentCardIndex] || {}),
                    rotate,
                    setRotate,
                  }}
                />
              </div>
              <Center style={{ backgroundColor: '', marginTop: 16 }}>
                <button class="button" onClick={clickNext}>
                  {!rotate ? 'OPEN' : 'DRAW'}
                </button>
              </Center>
            </>
          )}
        </section>
      </Center>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        closeButton={false}
        toastStyle={{ marginBottom: '10px' }}
      />
    </>
  );
}

export default App;
