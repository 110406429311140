import { useEffect, useState } from 'react';
import * as Tone from 'tone';

// Custom hook for Tone.js audio playback
function useToneAudio(url) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  // Initialize Tone.js
  useEffect(() => {
    Tone.start();
    setIsLoaded(true);
  }, []);

  // Create and set up the Tone.js player
  const player = new Tone.Player({
    url,
    autostart: false, // Do not auto-start playback
  }).toDestination();

  // Play the audio
  const play = () => {
    if (isLoaded) {
      player.start();
      setIsPlaying(true);
    }
  };

  // Stop the audio
  const stop = () => {
    if (isLoaded) {
      player.stop();
      setIsPlaying(false);
    }
  };

  return { isPlaying, play, stop };
}

export default useToneAudio;
