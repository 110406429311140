import { useState } from 'react';
import './Card.css';
import Kem from './IconComponents/Kem';
import Tag from './IconComponents/Tag';

const Card = (props) => {
  const {
    type = '',
    description = '',
    image,
    title,
    rotate,
    setRotate,
  } = props || {};
  console.log('props: ', props);
  description.split('').splice(0, 140).join('');
  const descriptionTrim = description.slice(0, 140);

  const clickRotate = () => {
    console.log('clickRotate: ');
    if (!rotate) {
      setRotate(true);
    }
  };

  return (
    <div className="card no-select" onClick={clickRotate}>
      <div className={`content ${rotate ? 'hover-content' : ''}`} style={[]}>
        <div className="back">
          <div className="back-content">
            <Kem />
            <strong>Random Card</strong>
          </div>
        </div>
        <div className="front">
          {image ? (
            <img
              style={{ position: 'absolute', zIndex: -1, objectFit: 'cover' }}
              src={image}
              alt="card"
              width={216}
              height={216}
            />
          ) : (
            <div className="img">
              <div className="circle"></div>
              <div className="circle" id="right"></div>
              <div className="circle" id="bottom"></div>
            </div>
          )}

          <div className="front-content">
            <small className="badge">{type}</small>
            <div className="description">
              <div className="description-inner" style={{}}>
                <div className="title">
                  <p className="title">
                    <strong>{title}</strong>
                  </p>
                  <Tag width={20} height={20} />
                </div>
                <p>{descriptionTrim}</p>
                {/* <p className="card-footer">30 Mins &nbsp; | &nbsp; 1 Serving</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
