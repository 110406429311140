import * as React from 'react';
const Tag = (props) => (
  <svg
    fillRule="nonzero"
    height="15px"
    width="15px"
    viewBox="0,0,256,256"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      style={{
        mixBlendMode: 'normal',
      }}
      textAnchor="none"
      fontSize="none"
      fontWeight="none"
      fontFamily="none"
      strokeDashoffset={0}
      strokeDasharray=""
      strokeMiterlimit={10}
      strokeLinejoin="miter"
      strokeLinecap="butt"
      strokeWidth={1}
      stroke="none"
      fillRule="nonzero"
      fill="#20c997"
    >
      <g transform="scale(8,8)">
        <path d="M25,27l-9,-6.75l-9,6.75v-23h18z" />
      </g>
    </g>
  </svg>
);
export default Tag;
